/* eslint-disable */
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import('./App'));

const Main = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-CMR7JRMNKY';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-CMR7JRMNKY');
    // Agregar el evento de Google Tag (gtag.js)
    gtag('event', 'apertura_piobox', {
      // <event_parameters>
    }); 
  }, []);

  return (
    <Provider store={configureStore()}>
      <Suspense fallback={<div className="loading" />}>
        <div className="h-100" translate="no">
          <App />
        </div>
      </Suspense>
    </Provider>
  );
  return (
    <Provider store={configureStore()}>
      <Suspense fallback={<div className="loading" />}>
        <div className="h-100" translate="no">
          <App />
        </div>
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
